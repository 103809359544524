







import { BButton, BCard, BCardText } from "bootstrap-vue";
import { Component, Vue } from "vue-property-decorator";

import userBaseModel from "@/api/user.base.model";
import { showErrorAlert } from "@/helpers";

@Component({
	components: { BCard, BCardText, BButton },
})
export default class Ead extends Vue {
	async openEad() {
		this.$store.dispatch("app/showLoading");
		try {
			const eadUrl = await userBaseModel.getEadUrl();
			window.open(eadUrl, "_blank");
		} catch (error) {
			console.error(error);
			showErrorAlert("O EAD está indisponível no momento. Tente novamente mais tarde.");
		}
		this.$store.dispatch("app/hideLoading");
	}
}
